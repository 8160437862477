import React from 'react'

import SEO from '../components/seo'
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout'

import HeroPage from '../components/views/hero-page'

export default () => {
  return (
    <Layout>
      <SEO title="About Sumo Japanese Restaurant" img={`/img/slider1.jpg`} />
      <HeroPage
        title="Sumo Japanese Restaurant"
        desc="Welcome to Sumo Japanese Restaurant! Easy Online Ordering, Dine In, Take Out or Delivery!"
        img="slider1.jpg"
      />
        <div style={{marginTop: '50px', marginBottom: '50px'}}>
            <Container>
                <Row>
                    <Col>
                      <h3>977 San Pablo Ave Albany, CA 94706</h3>
                        <p>
                        Sumo Sushi Restaurant offers delicious dining and takeout to Albany, CA.<br /><br />

                        Sumo Sushi Restaurant is a cornerstone in the Albany community and has been recognized for its outstanding Sushi cuisine, excellent service and friendly staff.<br /><br />

                        Our Sushi restaurant is known for its modern interpretation of classic dishes and its insistence on only using high quality fresh ingredients.
                        </p>
                        <div className="text-center mt-5">
                          <h3>Order from us online for Pickup or Delivery! It’s easy and quick!</h3>
                          <a className="btn btn-primary btn-lg btn-navbar-custom mt-3" target="_blank" rel="noreferrer" href="https://www.beyondmenu.com/48111/albany/sumo-sushi-restaurant-albany-94706.aspx">Order online</a>
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
  )
}
